import React, {useContext, useEffect, useState} from 'react';
import {
    AppBar, Button,
    Card,
    CardContent,
    Divider, Grid,
    Toolbar
} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {Link, Navigate, useNavigate, useOutletContext} from "react-router-dom";
import logo from "../assets/logo-header.png";
import ImageButton from "./ImageButton";

import btnProfile from "../assets/menu/profile/profil-b-g.png";
import btnProfileOver from "../assets/menu/profile/profil-b-j.png";
import btnDashboard from "../assets/menu/dashboard/dashboard-b-g.png";
import btnDashboardOver from "../assets/menu/dashboard/dashboard-b-j.png";
import btnLocation from "../assets/menu/location/locations-alt-3.png";
import btnShowcase from "../assets/menu/showcase/showcase_btn.png";

import {useTranslation} from "react-i18next";
import {AccountType} from "../constants/AccountType";
import RedirectToEntryPoint from "./RedirectToEntryPoint";
import usePageVisibility from "use-page-visibility";
import ContactForm from "./ContactForm";
import MobileContext from "../contexts/mobileContext";
import {getIsBranded, exitBranded} from "../features/branded/locationGroupSlice";
import {useSelector} from "react-redux";
import {useDispatch} from 'react-redux'
import WarningIcon from '@mui/icons-material/Warning';
import useFetch from "../hooks/useFetch";
import {accountDataFetched, fetchAccountNeeded} from "../features/account/accountSlice";
import useLocalStorageState from "use-local-storage-state";

export default function LoggedLayout(
    {
        children,
        section = null,
        allowedFor,
    }) {
    const classes = useStyles();
    const {t} = useTranslation('common');
    const [label, setLabel] = useState();

    const navigate = useNavigate();

    const accountType = localStorage.getItem('accountType');
    const [accessToken, setAccessToken] = useLocalStorageState('accessToken');

    const mobileContext = useContext(MobileContext);
    const isMobile = mobileContext.isMobile;

    // NB : ne fonctionne pas avec la config de base de webpack qui anonymise les classes
    // https://stackoverflow.com/questions/43800784/get-component-name-in-react
    // const childrenName = children.type.name;

    const [apiUri] = useOutletContext();
    const {call} = useFetch();

    const accountNeedFetch = useSelector(state => state.account.needFetch);
    const accountData = useSelector(state => state.account.accountData);
    const isBranded = useSelector(getIsBranded);
    const dispatch = useDispatch();

    usePageVisibility(visibilityChangedHandler);

    useEffect(() => {
        if (isBranded) {
            dispatch(exitBranded());
        }
    }, [isBranded]);

    useEffect(() => {
        if (accountNeedFetch) {
            accountGet();
        }
    }, [accountNeedFetch]);

    useEffect(() => {
        dispatch(fetchAccountNeeded());
    }, []);

    if (allowedFor != null && allowedFor != accountType) {
        return (<RedirectToEntryPoint/>)
    }

    async function accountGet() {
        call(apiUri + 'account', (data) => {
            dispatch(accountDataFetched(data));
        });
    }

    function visibilityChangedHandler(visible) {
        if (visible) {
            // Check access token again
            let oldAccessToken = JSON.stringify(accessToken);
            let newAccessToken = localStorage.getItem('accessToken');
            if (oldAccessToken != newAccessToken) {
                navigate("/login");
            }
        }
    }

    return (
        <>{accessToken == null ? <Navigate to="/login"/> :
            <div className={classes.root}>
                {/*<LanguageSelector apiUri={[apiUri]}/>*/}
                <AppBar
                    // position="static"
                    position="sticky"
                    sx={{
                        bgcolor: "black"
                    }}
                >
                    <Toolbar disableGutters={isMobile}
                             sx={{
                                 bgcolor: "black"
                             }}
                    >
                        <a
                            href="https://www.keepupcar.com"
                            target="_blank"
                        >
                            <img
                                src={logo}
                                className={isMobile ? classes.logo_mobile : classes.logo}
                            />
                        </a>
                        <Grid
                            container
                            alignItems="center"
                            justifyContent="flex-end"
                        >
                            <div className={classes.menuPage}>{t(label)}</div>
                            <Divider orientation="vertical" variant="middle" className={classes.divider} flexItem/>
                            <div style={{marginLeft: '20px'}}></div>

                            {accountType == AccountType.CUSTOMER &&
                                <Link to="/vehicle">
                                    <ImageButton src={btnDashboard} srcOver={btnDashboardOver}
                                                 height={isMobile ? '30px' : '50px'}
                                                 width={isMobile ? '30px' : '50px'}
                                                 selectedHover={section == 'VehicleIndex'}
                                    />
                                </Link>
                            }
                            {accountType == AccountType.PROVIDER &&
                                <>
                                    <Link to="/dashboard">
                                        <ImageButton
                                            src={btnDashboard}
                                            srcOver={btnDashboardOver}
                                        />
                                    </Link>
                                    <Link to="/location">
                                        <ImageButton
                                            src={btnLocation}
                                            srcOver={btnLocation}
                                        />
                                    </Link>
                                    <Link to="/showcase">
                                        <ImageButton
                                            src={btnShowcase}
                                            srcOver={btnShowcase}
                                            subIcon={
                                                accountData?.showcase_warning == '1' &&
                                                <WarningIcon
                                                    color="red"
                                                    style={{
                                                        marginLeft: -15
                                                    }}
                                                />}
                                        />
                                    </Link>
                                </>
                            }
                            <Link to="/profile">
                                <ImageButton src={btnProfile} srcOver={btnProfileOver}
                                             height={isMobile ? '30px' : '50px'}
                                             width={isMobile ? '30px' : '50px'}
                                             selectedHover={section == 'Profile'}
                                />
                            </Link>
                            {/*<ImageButton src={btnNotification} srcOver={btnNotificationOver}/>*/}
                        </Grid>
                    </Toolbar>

                    {/* AJouter un véhicle / Commander un service */}
                    {section == 'VehicleIndex' && isMobile &&
                        <Grid
                            container
                        >
                            <Grid item xs={6}
                                  textAlign="center"
                            >
                                <Link to="/vehicle/add">
                                    <Button
                                        variant="contained"
                                        color="yellow"
                                        sx={{
                                            m: '5px',
                                            height: '60px'
                                        }}
                                    >
                                        {t('vehicle.button_create_vehicle')}
                                    </Button>
                                </Link>
                            </Grid>
                            {
                                <Grid item xs={6}
                                      textAlign="center"
                                >
                                    <Link to="/service_order">
                                        <Button
                                            variant="contained"
                                            color="yellow"
                                            sx={{
                                                m: '5px',
                                                height: '60px'
                                            }}
                                        >
                                            {t('vehicle.button_order_service')}
                                        </Button>
                                    </Link>
                                </Grid>
                            }
                        </Grid>
                    }
                </AppBar>
                {/* https://stackoverflow.com/questions/643879/css-to-make-html-page-footer-stay-at-bottom-of-the-page-with-a-minimum-height-b */}
                <div style={{
                    // minHeight: '100%',
                    // display: 'table'

                    // For footer at the end ( not static )
                    // minHeight: '84vh',
                    // display: 'flex',
                    // flexDirection: 'column'
                }}>
                    <Card className={isMobile ? classes.root_mobile : classes.root}
                          variant="outlined"
                          style={{
                              // flex: 1,   // For footer at the end ( not static )
                              // backgroundColor: 'red'
                          }}
                    >
                        <CardContent className={classes.cardContent}
                                     sx={{
                                         p: isMobile ? '5px' : ''
                                     }}
                        >
                            <Grid
                                justifyContent="center"
                                alignItems="center"
                                container
                                sx={{
                                    p: '0px',
                                    m: '0px'
                                }}
                            >
                                {React.cloneElement(children, {setLabel: setLabel})}
                            </Grid>
                        </CardContent>
                    </Card>
                    {isMobile && // For the need help
                        <div style={{
                            // backgroundColor: 'blue',
                            minHeight: '50px'
                        }}/>
                    }
                    <ContactForm/>
                </div>
            </div>
        }
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: '#292e32',
        // height: '100%'
    },
    root_mobile: {
        flexGrow: 1,
        backgroundColor: '#292e32',
        padding: '0px',
    },
    cardContent: {
        backgroundColor: '#292e32'
        // backgroundColor: 'red'
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    large: {
        width: theme.spacing(20),
        height: theme.spacing(20),
    },
    logo: {
        height: '100px',
        margin: '10px'
    },
    logo_mobile: {
        height: '40px',
        margin: '13px'
    },
    menuBtnImg: {
        height: '50px'
    },
    divider: {
        background: theme.palette.yellow.main,
    },
    menuPage: {
        color: theme.palette.yellow.main,
        paddingRight: '20px'
    }
}));
